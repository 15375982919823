<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Analytics">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="loadCharts"
        ></region-chooser>
      </template>
      <el-row :gutter="24" style="margin-bottom: 24px;">
        <el-col :span="24">
          <el-date-picker
            v-model="dateRange"
            :picker-options="pickerOptions"
            :clearable="false"
            type="daterange"
            range-separator="–"
            start-placeholder="Start"
            end-placeholder="Ende"
            format="dd.MM.yyyy"
            size="large"
            @change="loadCharts"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-bottom: 24px;">
        <el-col :span="24">
          <chart-card
            :chart-options="visitsChartOptions"
            :series="visitsSeries"
            type="line"
            :heading="
              visitsSeries.length > 1
                ? 'Besucher & Seitenaufrufe'
                : 'Seitenaufrufe'
            "
            :loading="loading"
            height="350"
          ></chart-card>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <chart-card
            :chart-options="popularEventsChartOptions"
            :series="popularEventsSeries"
            type="bar"
            heading="Meist aufgerufene Veranstaltungen"
            :loading="loading"
            height="350"
          ></chart-card>
        </el-col>
        <el-col :span="12">
          <chart-card
            :chart-options="popularArticleCollectionsChartOptions"
            :series="popularArticleCollectionsSeries"
            type="bar"
            heading="Meist aufgerufene Artikelsammlungen"
            :loading="loading"
            height="400"
          ></chart-card> </el-col
      ></el-row>
      <el-row :gutter="24"
        ><el-col :span="12">
          <chart-card
            :chart-options="popularInstitutionCollectionsChartOptions"
            :series="popularInstitutionCollectionsSeries"
            type="bar"
            heading="Meist aufgerufene Einrichtungssammlungen"
            :loading="loading"
            height="400"
          ></chart-card>
        </el-col>
        <el-col :span="12">
          <chart-card
            :chart-options="popularArticlesChartOptions"
            :series="popularArticlesSeries"
            type="bar"
            heading="Meist aufgerufene Artikel"
            :loading="loading"
            height="350"
          ></chart-card> </el-col
      ></el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <chart-card
            :chart-options="popularInstitutionsChartOptions"
            :series="popularInstitutionsSeries"
            type="bar"
            heading="Meist aufgerufene Einrichtungen"
            :loading="loading"
            height="350"
          ></chart-card>
        </el-col>
        <el-col :span="12">
          <chart-card
            :chart-options="popularVideosChartOptions"
            :series="popularVideosSeries"
            type="bar"
            heading="Meist aufgerufene Videos"
            :loading="loading"
            height="350"
          ></chart-card> </el-col
      ></el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <chart-card
            :chart-options="activeOrgsChartOptions"
            :series="activeOrgsSeries"
            type="bar"
            heading="Aktivste Veranstalter"
            :loading="loading"
            height="850"
          ></chart-card>
        </el-col>
      </el-row>
      <el-row :gutter="24"
        ><el-col v-if="isAfterCutoff" :span="12">
          <chart-card
            :chart-options="deviceTypesChartOptions"
            :series="deviceTypesSeries"
            type="pie"
            heading="Gerätetypen"
            height="350"
            :loading="loading"
          ></chart-card>
        </el-col>
        <el-col v-if="isAfterCutoff" :span="12">
          <chart-card
            :chart-options="browsersChartOptions"
            :series="browsersSeries"
            type="pie"
            heading="Browser"
            height="350"
            :loading="loading"
          ></chart-card> </el-col
      ></el-row>
    </page-content>
  </div>
</template>

<script>
import { format, isAfter } from "date-fns"
import RegionAnalyticsRepository from "@/repositories/region_analytics_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import RegionChooser from "@/components/RegionChooser"
import ChartCard from "@/components/ChartCard"

export default {
  metaInfo: {
    title: "Analytics"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    RegionChooser,
    ChartCard
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      format,
      loading: true,
      pickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Letzte 7 Tage",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit("pick", [start, end])
            }
          },
          {
            text: "Letzte 30 Tage",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 29)
              picker.$emit("pick", [start, end])
            }
          },
          {
            text: "Letzte 90 Tage",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 89)
              picker.$emit("pick", [start, end])
            }
          },
          {
            text: "Letzte 6 Monate",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 179)
              picker.$emit("pick", [start, end])
            }
          },
          {
            text: "Letztes Jahr",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              end.setTime(end.getTime() - 3600 * 1000 * 24)
              start.setTime(end.getTime() - 3600 * 1000 * 24 * 364)
              picker.$emit("pick", [start, end])
            }
          }
        ]
      },
      dateRange: "",
      visitsChartOptions: {
        title: {
          text: "im Zeitraum"
        },
        xaxis: {
          type: "datetime"
        }
      },
      popularEventsChartOptions: {
        colors: ["#775dd0"],
        title: {
          text: "im Zeitraum"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      },
      activeOrgsChartOptions: {
        colors: ["#775dd0"],
        title: {
          text: "im Zeitraum"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        yaxis: {
          labels: {
            maxWidth: 400
          }
        }
      },
      popularInstitutionsChartOptions: {
        colors: ["#E91E63"],
        title: {
          text: "im Zeitraum"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      },
      popularArticlesChartOptions: {
        title: {
          text: "im Zeitraum"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      },
      popularVideosChartOptions: {
        colors: ["#feb019"],
        title: {
          text: "im Zeitraum"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      },
      popularInstitutionCollectionsChartOptions: {
        colors: ["#E91E63"],
        title: {
          text: "im Zeitraum"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      },
      popularArticleCollectionsChartOptions: {
        title: {
          text: "im Zeitraum"
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      },
      visitsSeries: [],
      popularEventsSeries: [],
      popularInstitutionsSeries: [],
      popularArticlesSeries: [],
      popularVideosSeries: [],
      popularArticleCollectionsSeries: [],
      popularInstitutionCollectionsSeries: [],
      deviceTypesSeries: [],
      deviceTypesLabels: [],
      browsersSeries: [],
      browsersLabels: [],
      activeOrgsSeries: []
    }
  },
  computed: {
    deviceTypesChartOptions() {
      return {
        labels: this.deviceTypesLabels
      }
    },
    browsersChartOptions() {
      return {
        labels: this.browsersLabels
      }
    },
    isAfterCutoff() {
      return isAfter(this.dateRange[0], new Date("2022-12-31"))
    }
  },
  async created() {
    const end = new Date()
    end.setTime(end.getTime() - 3600 * 1000 * 24)
    const start = new Date()
    start.setTime(end.getTime() - 3600 * 1000 * 24 * 29)
    this.dateRange = [start, end]
    this.loadCharts()
  },
  methods: {
    async loadCharts() {
      this.loading = true
      try {
        let data = await RegionAnalyticsRepository.visits(
          format(this.dateRange[0], "yyyy-LL-dd"),
          format(this.dateRange[1], "yyyy-LL-dd")
        )
        this.visitsSeries = [
          {
            name: "Seitenaufrufe",
            data: data.events
          }
        ]

        if (this.isAfterCutoff) {
          if (data.visits && data.visits.length > 0) {
            this.visitsSeries.push({
              name: "Besucher",
              data: data.visits
            })
          }

          this.deviceTypesSeries = data.device_types.values
          this.deviceTypesLabels = data.device_types.labels
          this.browsersSeries = data.browsers.values
          this.browsersLabels = data.browsers.labels
        }

        this.popularEventsSeries = [
          {
            name: "Aufrufe",
            data: data.popular_events
          }
        ]
        this.popularInstitutionsSeries = [
          {
            name: "Aufrufe",
            data: data.popular_institutions
          }
        ]
        this.popularArticlesSeries = [
          {
            name: "Aufrufe",
            data: data.popular_articles
          }
        ]
        this.popularVideosSeries = [
          {
            name: "Aufrufe",
            data: data.popular_videos
          }
        ]
        this.popularArticleCollectionsSeries = [
          {
            name: "Aufrufe",
            data: data.popular_article_collections
          }
        ]
        this.popularInstitutionCollectionsSeries = [
          {
            name: "Aufrufe",
            data: data.popular_institution_collections
          }
        ]
        this.activeOrgsSeries = [
          {
            name: "Eingetragene Veranstaltungen",
            data: data.most_active_orgs
          }
        ]

        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    }
  }
}
</script>
